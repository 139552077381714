import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { A11yControls } from './A11yControls'
import About from './About'
import Covid from './Covid'
import DesktopFooter from './Footer/DesktopFooter'
import Footer, { FOOTER_HEIGHT_REM } from './Footer/Footer'
import LangSwitcher from './Footer/LangSwitcher'
import Location from './Location/Location'
import PanoMap from './PanoMap/PanoMap'
import { Actions, initialState, reducer } from './reducer'
import Search from './Search/Search'
import { getLangShortcode, LocationFilters } from './utils'

export const Theme = {
  CONTRAST: 'CONTRAST',
  LIGHT: 'LIGHT',
  DARK: 'DARK',
}

const App = () => {
  // get the viewport height multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  const [cities, setCities] = useState([])
  const [locationFilter, setLocationFilter] = useState(LocationFilters.CITIES)

  const { i18n } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  })
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })

  useEffect(() => {
    const path = '/data/cities.json'
    const filter = LocationFilters.CITIES.routeBase
    fetch(path)
      .then((response) => response.json())
      .then((cities) =>
        cities
          .map((city) => ({
            ...city,
            routeBase: filter,
          }))
          .sort((a, b) => {
            const langShortcode = getLangShortcode(i18n.language)
            const aName = a.shortname[langShortcode]
            const bName = b.shortname[langShortcode]
            return aName.localeCompare(bName)
          })
      )
      .then((cities) => {
        setCities(cities)
        dispatch({ type: Actions.citiesFetched, payload: cities })
      })
    fetch('/data/hotels.json')
      .then((response) => response.json())
      .then((hotels) =>
        hotels.map((hotel) => ({
          ...hotel,
          routeBase: LocationFilters.HOTELS.routeBase,
        }))
      )
    fetch('/data/attractions.json')
      .then((response) => response.json())
      .then((attractions) =>
        attractions.map((attraction) => ({
          ...attraction,
          routeBase: LocationFilters.ATTRACTIONS.routeBase,
        }))
      )
  }, [i18n])

  return (
    <Router
      style={{
        position: 'relative',
      }}
    >
      <main
        style={{
          height: isMobileDevice
            ? `calc(${vh * 100}px - ${FOOTER_HEIGHT_REM}rem`
            : `calc(${vh * 100}px`,
          position: 'relative',
        }}
        className={`relative ${
          state.highContrast ? 'bg-black text-yellow-400' : 'inherit'
        }`}
      >
        <div className="absolute top-0 right-0 z-10">
          <A11yControls dispatch={dispatch} state={state} />
        </div>
        <Switch>
          {[
            LocationFilters.CITIES.routeBase,
            LocationFilters.HOTELS.routeBase,
            LocationFilters.ATTRACTIONS.routeBase,
          ].map((routeBase, idx) => (
            <Route
              key={idx + routeBase}
              path={`${routeBase}/:locationName`}
              children={<Location state={state} />}
            />
          ))}
          <Route
            path="/mapa/:locationName"
            children={
              <PanoMap
                cities={cities}
                locationFilter={locationFilter}
                state={state}
                dispatch={dispatch}
                setLocationFilter={setLocationFilter}
              />
            }
          />
          <Route path="/mapa">
            <PanoMap
              cities={cities}
              locationFilter={locationFilter}
              dispatch={dispatch}
              setLocationFilter={setLocationFilter}
              state={state}
            />
          </Route>
          <Route path="/szukaj">
            <Search
              state={state}
              dispatch={dispatch}
              cities={cities}
              locationFilter={locationFilter}
              setLocationFilter={setLocationFilter}
            />
          </Route>
          <Route path="/o-projekcie">
            <About state={state} dispatch={dispatch} />
          </Route>

          <Route path="/covid">
            <Covid state={state} dispatch={dispatch} />
          </Route>

          <Route path="/">
            <Location state={state} dispatch={dispatch} />
          </Route>
        </Switch>
      </main>
      {isMobileDevice ? (
        <Footer state={state} />
      ) : (
        <DesktopFooter state={state} />
      )}
      {isDesktopOrLaptop && (
        <div className="absolute top-0 right-0 mt-6 mr-24">
          <LangSwitcher state={state} />
        </div>
      )}
    </Router>
  )
}

export default App
