import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import * as Markdown from 'react-remarkable'
import {useMediaQuery} from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {ArrowLeftIcon} from './icons'
import {Actions, Theme} from './reducer'

const About = ({state, dispatch}) => {
    const {t} = useTranslation()
    const history = useHistory()
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)',
    })
    const isSmartphone = useMediaQuery({
        query: '(max-device-width: 586px)',
    })

    useEffect(() => {
        if (!state.highContrast) {
            dispatch({type: Actions.themeSwitch, payload: Theme.LIGHT})
        }
    }, [state.highContrast, dispatch])

    const Logos = [
        {
            title: 'Interreg - logo',
            src: '/logo-interreg.png'
        }
    ]

    return (
        <section
            className={`m-auto h-full flex flex-col pt-3 items-center ${
                isDesktopOrLaptop && 'pb-20'
            } ${!isDesktopOrLaptop && 'px-3'}`}
            style={{maxWidth: '54rem'}}
        >
            <nav className="flex flex-row w-full items-center justify-evenly mb-6">
                <button
                    onClick={() => history.goBack()}
                    className="flex flex-row items-center flex-1 text-left hover:text-green-600"
                >
                    <ArrowLeftIcon/>
                    {!isSmartphone && <span className="ml-2">{t('Back')}</span>}
                </button>
                <h1>{t('About the project.title')}</h1>
                <span className="flex flex-1 justify-end opacity-0" role="img"></span>
            </nav>

            <main
                className={`flex flex-col px-3 sm:px-8 py-5 font-thin overflow-auto text-justify ${
                    state.fontSize && 'text-lg'
                }`}
                style={{color: state.highContrast ? '#f6e05e' : '#2d4059'}}
            >
                <Markdown
                    source={t('About the project.content')}
                    options={{html: true}}
                />
                {Logos.map((logo, idx) => (
                    <a
                        key={idx}
                        href={logo.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-row justify-center"
                    >
                        <img
                            alt={logo.title}
                            src={logo.src}
                            className={`${
                                idx !== 0 ? 'w-1/3' : 'w-3/5'
                            } h-auto object-fill mt-10`}
                        />
                    </a>
                ))}
                <h3 className="mt-10 normal-case">{t('About the project.balticManors')}</h3>
            </main>
        </section>
    )
}

export default About
