import { blue, green } from '@material-ui/core/colors'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { ArrowLeftIcon } from '../icons'
import { Actions, Theme } from '../reducer'
import { getLangShortcode, getUrlWithLang, LocationFilters } from '../utils'
import contrastMapStyle from './contrastMapStyles'
import LocationPopup from './LocationPopup'

const PanoMap = ({ cities, locationFilter, state, dispatch }) => {
  // geograficzny środek woj. zachodniopomorskiego wg. Google Maps
  const [center, setCenter] = useState({
    lat: 53.932350212487826,
    lng: 16.13815909825863
  })
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [popupVisible, setPopupVisible] = useState(null)
  const isViewportForBiggerMapZoom = useMediaQuery({
    query: '(min-device-width: 580px)',
  })
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })
  const googleMapsApiKey = 'AIzaSyAOBWGNy4r3y3zlGk4z-9VVWwhJsIFCv_c'
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const [locations, setLocations] = useState([])
  const googleMapsRef = useRef(null)

  useEffect(() => {
    setLocations(
      cities.map((city) => ({
        ...city,
        filterId: LocationFilters.CITIES.id,
      }))
    )
  }, [cities, locationFilter])

  useEffect(() => {
    if (!state.highContrast) {
      dispatch({ type: Actions.themeSwitch, payload: Theme.LIGHT })
    }
  }, [state.highContrast, dispatch])

  const getPinSymbol = (locationFilterId, selected = false) => {
    return {
      path: 'M7 17C7 17 0 10.87 0 7C-1.36979e-08 6.08075 0.18106 5.1705 0.532843 4.32122C0.884626 3.47194 1.40024 2.70026 2.05025 2.05025C2.70026 1.40024 3.47194 0.884626 4.32122 0.532843C5.1705 0.18106 6.08075 0 7 0C7.91925 0 8.82951 0.18106 9.67878 0.532843C10.5281 0.884626 11.2997 1.40024 11.9497 2.05025C12.5998 2.70026 13.1154 3.47194 13.4672 4.32122C13.8189 5.1705 14 6.08075 14 7C14 10.87 7 17 7 17ZM7 9C7.53043 9 8.03914 8.78929 8.41421 8.41421C8.78929 8.03914 9 7.53043 9 7C9 6.46957 8.78929 5.96086 8.41421 5.58579C8.03914 5.21071 7.53043 5 7 5C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7C5 7.53043 5.21071 8.03914 5.58579 8.41421C5.96086 8.78929 6.46957 9 7 9Z',
      fillColor: selected
        ? '#519400'
        : locationFilterId === LocationFilters.CITIES.id
        ? green[600]
        : blue[600],
      fillOpacity: 0.9,
      anchor: { x: 7, y: 17 },
      scale: selected ? 3 : 1.5,
      strokeWeight: 0,
    }
  }

  const pinClickedHandler = (clickEvent) => {
    const { lat, lng } = {
      lat: clickEvent.latLng.lat(),
      lng: clickEvent.latLng.lng(),
    }
    const location = locations.find(
      (loc) => loc.coords.lat === lat && loc.coords.lng === lng
    )
    setSelectedLocation(location)
    setPopupVisible(true)
    setCenter(location.coords)
  }

  return (
    <div
      style={containerStyle}
      className={`flex flex-col justify-center items-center`}
    >
      <div
        className="w-full"
        style={{
          position: 'absolute',
          zIndex: 1,
          transform: 'scaleY(-1)',
        }}
      />
      <div className="flex flex-col w-full" style={{ maxWidth: '54rem' }}>
        <nav className="flex flex-row flex-1 items-center justify-between py-3">
          <button
            onClick={() => history.goBack()}
            className="flex flex-row items-center flex-1 text-left hover:text-green-600 ml-3"
          >
            <ArrowLeftIcon />
            {!isSmartphone && <span className="ml-2">{t('Back')}</span>}
          </button>
          <h1 className="flex-1 justify-center">{t('Map.Label')}</h1>
          <span className="flex flex-1 justify-end opacity-0" role="img"></span>
        </nav>
      </div>
      <div className="flex flex-1 w-full items-center justify-center">
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap
            ref={googleMapsRef}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={isViewportForBiggerMapZoom ? 8 : 7}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              clickableIcons: false,
              styles: state.highContrast && contrastMapStyle,
            }}
          >
            {locations.map(
              (location, idx) =>
                location.coords && (
                  <Marker
                    key={createKey(idx, location.coords)}
                    position={location.coords}
                    icon={getPinSymbol(
                      location.filterId,
                      selectedLocation &&
                        location.name === selectedLocation.name
                    )}
                    onClick={pinClickedHandler}
                  />
                )
            )}
          </GoogleMap>
        </LoadScript>
      </div>
      {popupVisible && (
        <LocationPopup
          state={state}
          close={() => setPopupVisible(false)}
          label={selectedLocation.shortname[getLangShortcode(i18n.language)]}
          buttonUrl={getUrlWithLang(selectedLocation.url, i18n.language)}
        />
      )}
    </div>
  )
}

const createKey = (id, location) => `${id}${location.lat + location.lng}`

const containerStyle = {
  width: '100%',
  height: '100%',
}

export default PanoMap
