import React, { useEffect, useRef } from 'react'
import { Viewer } from 'photo-sphere-viewer'
import { useTranslation } from 'react-i18next'
import { Actions } from '../reducer'

const Pano = ({ className, panoUrl, dispatch, flyView = false }) => {
  const { i18n } = useTranslation()
  const FLYVIEW_LAT = '-15deg'

  let panoViewerRef = useRef(null)

  useEffect(() => {
    const panoViewer = new Viewer({
      container: panoViewerRef.current,
      panorama: panoUrl,
      navbar: null,
      // TODO podmienic na pomeranie
      loadingImg: 'logo.png',
      // loadingImg: i18n.language && i18n.language.includes('pl') ? '/loading_pl.svg' : '/loading_de.svg',
      defaultLat: flyView ? FLYVIEW_LAT : '0deg',
      autorotateDelay: 1,
      autorotateSpeed: '0.25rpm',
      mousewheel: false,
      mousemove: false,
      defaultZoomLvl: 40,
    })

    panoViewer.on('ready', async (_) =>
      dispatch({ type: Actions.panoReadyToggle })
    )
    return () => panoViewer.destroy()
  }, [panoViewerRef, panoUrl, dispatch, flyView, i18n.language])

  return <div ref={panoViewerRef} className={`w-full h-full ${className}`} />
}

export default Pano
