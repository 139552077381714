import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { Theme } from './App'
import { ArrowLeftIcon } from './icons'
import * as Markdown from 'react-remarkable'
import { Actions } from './reducer'

const Covid = ({ state, dispatch }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })

  useEffect(() => {
    if (!state.highContrast) {
      dispatch({ type: Actions.themeSwitch, payload: Theme.LIGHT })
    }
  }, [state.highContrast, dispatch])

  return (
    <section
      className={`m-auto h-full flex flex-col pt-3 items-center ${
        isDesktopOrLaptop && 'pb-20'
      } ${!isDesktopOrLaptop && 'px-3'}`}
      style={{ maxWidth: '54rem' }}
    >
      <nav className="flex flex-row w-full items-center justify-evenly mb-6">
        <button
          onClick={() => history.goBack()}
          className="flex flex-row items-center flex-1 text-left hover:text-green-600"
        >
          <ArrowLeftIcon />
          {!isSmartphone && <span className="ml-2">{t('Back')}</span>}
        </button>
        <h1>{t('Covid19.title')}</h1>
        <span className="flex flex-1 justify-end opacity-0" role="img"></span>
      </nav>

      <main className="flex flex-col px-3 sm:px-8 py-5 font-thin overflow-auto text-gray-700">
        <Markdown source={t('Covid19.content')} options={{ html: true }} />
      </main>
    </section>
  )
}

export default Covid
