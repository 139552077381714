import React from 'react'
import { FacebookIcon, InstagramIcon } from '../icons'

const SocialMedia = ({ state }) => {
  const FB_LINK = 'https://www.facebook.com/travelbalticmanors'
  const IG_LINK = 'https://www.instagram.com/balticmanors/'

  return (
    <div className="flex flex-row m-4 text-white">
      <a
        href={FB_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={`${state.highContrast && 'text-yellow-400'}`}
      >
        <FacebookIcon className={`w-6 ${state.fontSize && 'big'}`} />
      </a>
      <a
        href={IG_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={`${state.highContrast && 'text-yellow-400'}`}
      >
        <InstagramIcon className={`ml-4 w-6 ${state.fontSize && 'big'}`} />
      </a>
    </div>
  )
}

export default SocialMedia
