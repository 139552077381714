import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  FlagIcon,
  HomeIcon,
  InfoIcon,
  PinIcon,
  SearchIcon,
} from '../icons'
import LangSwitcher from './LangSwitcher'
import { NavItem } from './NavItem'

export const FOOTER_HEIGHT_REM = '3.4'

const Footer = ({ state }) => {
  const { i18n } = useTranslation()
  const [drawerVisible, setDrawerVisible] = useState(false)
  let match = useLocation()

  return (
    <div className="flex flex-col">
      <div
        className="w-full"
        style={{
          position: 'relative',
          height: `${FOOTER_HEIGHT_REM / 2}rem`,
          top: `-${FOOTER_HEIGHT_REM / 2}rem`,
        }}
      />
      <nav
        style={styles}
        className={`flex flex-row justify-around items-center lg:px-64 font-sans ${
          state.highContrast ? 'bg-black' : 'bg-gray-100'
        }`}
      >
        <NavItem
          active={match.pathname === '/'}
          to="/"
          state={state}
          i18nKey="Home"
        >
          <HomeIcon />
        </NavItem>
        <NavItem
          active={match.pathname === '/mapa'}
          to="/mapa"
          state={state}
          i18nKey="Map.Label"
        >
          <PinIcon />
        </NavItem>
        <NavItem
          active={match.pathname === '/szukaj'}
          to="/szukaj"
          state={state}
          i18nKey="Search"
        >
          <SearchIcon />
        </NavItem>
        <NavItem
          active={match.pathname === '/o-projekcie'}
          to="/o-projekcie"
          state={state}
          i18nKey="About the project.title"
        >
          <InfoIcon />
        </NavItem>
        <button
          onClick={() => setDrawerVisible(true)}
          className="flex flex-col items-center"
        >
          <FlagIcon country={i18n.language} />
          <span
            className={`text-xs ${state.highContrast && 'text-yellow-400'}`}
          >
            {i18n.language && i18n.language.substring(0, 2).toUpperCase()}
          </span>
        </button>
        <LangSwitcher
          state={state}
          setDrawerVisible={setDrawerVisible}
          drawerVisible={drawerVisible}
        />
      </nav>
    </div>
  )
}

const styles = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: `${FOOTER_HEIGHT_REM}rem`,
}

export default Footer
