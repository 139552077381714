import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Theme } from '../App'
import {
  FlagIcon,
  HomeIcon,
  InfoIcon,
  PinIcon,
  SearchIcon,
} from '../icons'
import { getLangName } from '../utils'
import LangSwitcher from './LangSwitcher'
import { useLocation } from 'react-router-dom'
import { DesktopNavItem } from './DesktopNavItem'

export const FOOTER_HEIGHT_REM = '5'

const DesktopFooter = ({ state }) => {
  const { i18n } = useTranslation()
  const [drawerVisible, setDrawerVisible] = useState(false)
  let match = useLocation()

  return (
    <nav
      style={styles}
      className={`flex justify-center \
      absolute bottom-0`}
    >
      <div className="flex font-bold my-4 justify-between flex-row w-full max-w-6xl">
        <DesktopNavItem
          active={match.pathname === '/'}
          to="/"
          state={state}
          i18nKey="Home"
        >
          <HomeIcon className="mr-6" />
        </DesktopNavItem>
        <DesktopNavItem
          active={match.pathname === '/mapa'}
          to="/mapa"
          state={state}
          i18nKey="Map.Label"
        >
          <PinIcon className="mr-6" />
        </DesktopNavItem>
        <DesktopNavItem
          active={match.pathname === '/szukaj'}
          to="/szukaj"
          state={state}
          i18nKey="Search"
        >
          <SearchIcon className="mr-6" />
        </DesktopNavItem>
        <DesktopNavItem
          active={match.pathname === '/o-projekcie'}
          to="/o-projekcie"
          state={state}
          i18nKey="Project"
        >
          <InfoIcon className="mr-6" />
        </DesktopNavItem>
        <button
          onClick={() => setDrawerVisible(true)}
          className={`${
            state.highContrast
              ? 'border border-yellow-400 text-yellow-400'
              : state.theme === Theme.LIGHT
              ? 'hover:bg-gray-700 hover:text-white bg-white'
              : 'hover:text-gray-700 hover:bg-white text-white'
          } rounded-full px-8 py-3 flex items-center`}
        >
          <FlagIcon
            country={
              i18n && i18n.language
                ? i18n.language.substring(0, 2).toLowerCase()
                : 'en'
            }
            className="mr-6"
          />
          <span className="font-bold">{getLangName(i18n.language)}</span>
        </button>
        <LangSwitcher
          state={state}
          setDrawerVisible={setDrawerVisible}
          drawerVisible={drawerVisible}
        />
      </div>
    </nav>
  )
}

const styles = {
  height: `${FOOTER_HEIGHT_REM}rem`,
  right: '4rem',
  left: '4rem',
}

export default DesktopFooter
