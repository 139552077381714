import React from 'react'
import { ContrastIcon, FontSizeIcon } from './icons'
import { Actions, Theme } from './reducer'

export const A11yControls = ({ state, dispatch }) => {
  return (
    <div
      className={`flex flex-row m-4 ${
        state.highContrast
          ? 'text-yellow-400'
          : state.theme === Theme.LIGHT
          ? 'text-gray-700'
          : 'text-white'
      } fill-current items-center`}
    >
      <div onClick={() => dispatch({ type: Actions.highContrastToggle })}>
        <ContrastIcon className={`cursor-pointer ${state.fontSize && 'big'}`} />
      </div>
      <div onClick={() => dispatch({ type: Actions.biggerFontsToggle })}>
        <FontSizeIcon
          className={`big ml-3 relative cursor-pointer ${
            state.fontSize && 'bigger'
          }`}
          style={{ top: '-10px' }}
        />
      </div>
    </div>
  )
}
