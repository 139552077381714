import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { Actions, Theme } from '../reducer'
import { ArrowLeftIcon, FlagIcon, HeliIcon, SearchIcon } from '../icons'
import { getLangShortcode, getUrlWithLang } from '../utils'

const Search = ({ cities, dispatch, state }) => {
  const { t, i18n } = useTranslation()
  const [elements, setElements] = useState([])
  const [searchPhrase, setSearchPhrase] = useState(null)
  const history = useHistory()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })

  useEffect(() => {
    const langShortcode = getLangShortcode(i18n.language)
    setElements(
      searchPhrase
        ? cities.filter((city) => {
            const test = (name, phrase) =>
              name.toLowerCase().includes(phrase.toLowerCase())
            return test(city.shortname[langShortcode], searchPhrase)
          })
        : cities
    )
  }, [cities, searchPhrase, i18n.language])

  useEffect(() => {
    if (!state.highContrast) {
      dispatch({ type: Actions.themeSwitch, payload: Theme.LIGHT })
    }
  }, [state.highContrast, dispatch])

  const onSearchPhraseChange = (event) => setSearchPhrase(event.target.value)

  return (
    <div
      className={`m-auto h-full flex flex-col pt-3 ${
        isDesktopOrLaptop && 'pb-20'
      } ${!isDesktopOrLaptop && 'px-3'}`}
      style={{ maxWidth: '54rem' }}
    >
      <nav className="flex flex-row w-full items-center justify-between">
        <button
          onClick={() => history.goBack()}
          className="flex flex-row items-center flex-1 text-left hover:text-green-600"
        >
          <ArrowLeftIcon />
          {!isSmartphone && <span className="ml-2">{t('Back')}</span>}
        </button>
        <h1 className="flex-1 justify-center">{t('Search')}</h1>
        <button
          className="flex flex-1 justify-end hover:text-green-600 items-center outline-none"
          role="img"
        ></button>
      </nav>
      <main className="overflow-y-auto w-full items-center flex flex-col mt-4">
        <div className="w-full flex flex-row items-center relative">
          <input
            onChange={onSearchPhraseChange}
            placeholder={t('Enter the phrase')}
            className={`${
              state.highContrast
                ? 'text-yellow-400 bg-transparent border-yellow-400 placeholder-yellow-400'
                : 'bg-gray-100 border-gray-300'
            } rounded-full w-full p-3 border text-sm mb-3 outline-none ${
              state.fontSize && 'text-lg'
            }`}
          />
          <SearchIcon
            className={`mr-4 ${
              state.highContrast ? 'text-yellow-400' : 'text-gray-400'
            } absolute right-0 mb-3`}
          />
        </div>
        {elements.length > 0 && (
          <h3
            className={`${
              state.highContrast ? 'text-yellow-400' : 'text-gray-500'
            } my-1 self-center ${state.fontSize && 'text-base'}`}
          >
            {t('Found')}: {elements.length}
          </h3>
        )}
        {elements.length > 0 ? (
          <div className="w-full">
            {elements.map((location, index) => (
              <div
                key={index}
                className={`flex flex-wrap flex-row pb-3 ${
                  state.highContrast
                    ? 'bg-transparent text-yellow-400 border-yellow-400 border'
                    : 'bg-gray-100 text-gray-700'
                }  bg-opacity-50 my-3 rounded-lg`}
              >
                <div className="flex-1 flex flex-col px-3">
                  <div className="flex flex-row pt-3">
                    <a
                      href={getUrlWithLang(location.url, i18n.language)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`underline text-lg mr-4 flex-1 ${
                        state.fontSize && 'text-xl'
                      }`}
                    >
                      {location.shortname[getLangShortcode(i18n.language)]}
                    </a>
                    {location.flyview && <HeliIcon />}
                    <FlagIcon
                      country={location.country}
                      className="small ml-2"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className={`flex flex-1 flex-col ${
              state.highContrast ? 'text-yellow-400' : 'text-gray-500'
            } mt-10`}
          >
            <span>
              {t('No city found')}&nbsp;
              <span role="img" aria-label="sad-face">
                😢
              </span>
            </span>
            <span>{t('Change search phrase')}</span>
          </div>
        )}
      </main>
    </div>
  )
}

export default Search
