import {AnimatePresence} from 'framer-motion'
import React, {useEffect, useState} from 'react'
import Pano from '../Pano/Pano'
import {Actions, Theme} from '../reducer'
import {getRandomLocation, isSafari} from '../utils'
import Intro from './Intro'

export const PanoKeys = [
    {path: 'chwalimki', flyView: false},
    {path: 'dobrowo', flyView: false},
    {path: 'juchowo', flyView: true},
    {path: 'luboradza', flyView: false},
    {path: 'nosowo', flyView: true},
    {path: 'ryman', flyView: true}
]

const Location = ({state, dispatch}) => {
    const [location] = useState(getRandomLocation(PanoKeys))

    useEffect(() => {
        if (!state.highContrast) {
            dispatch({type: Actions.themeSwitch, payload: Theme.DARK})
        }
    }, [state.highContrast, dispatch])

    return (
        location && (
            <div className="h-full min-h-full bg-black">
                {!state.highContrast && (
                    <Pano
                        flyView={location.flyView}
                        panoUrl={`/panos/${location.path}.${isSafari ? 'jpg' : 'webp'}`}
                        dispatch={dispatch}
                    />
                )}
                <AnimatePresence>
                    {state.panoReady && <Intro state={state}/>}
                </AnimatePresence>
            </div>
        )
    )
}

export default Location
