import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import SiteLogo from '../logo.png'
import SiteLogoContrast from '../logo2.png'
import { simulateButtonPress } from '../utils'
import SocialMedia from './SocialMedia'

const Intro = ({ setLocation, currentLocation, state, dispatch }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const [logo, setLogo] = useState(SiteLogo)
  const isSmartphone = useMediaQuery({
    query: '(max-device-width: 586px)',
  })
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })

  useEffect(() => {
    if (state.highContrast) {
      setLogo(SiteLogoContrast)
    } else {
      setLogo(SiteLogo)
    }
  }, [i18n.language, state.highContrast])

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className={`absolute top-0 h-full w-full flex flex-col justify-center items-center bg-opacity-50 bg-black ${
        isDesktopOrLaptop && 'pb-20'
      }`}
    >
      <div className="absolute right-0" style={{ top: '3rem' }}>
        <SocialMedia state={state} />
      </div>
      <div
        style={{ height: isSmartphone ? '95%' : '40rem' }}
        className="flex flex-col items-center mx-6 lg:mx-64 justify-between"
      >
        <motion.div
          className="relative flex justify-center"
          style={{ width: isSmartphone ? '14rem' : '16rem' }}
          animate={{ opacity: [0, 1], top: [2, 0] }}
        >
          <img alt="Camino360 - logo" src={logo} className="w-4/5 sm:w-full" />
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 0, 1], top: [2, 2, 0] }}
          className={`${
            state.highContrast ? 'text-yellow-400' : 'text-white'
          } flex flex-col`}
        >
          <h1 className="justify-center text-center text-xl sm:text-3xl">
            {t('Visit region')}
          </h1>
        </motion.div>
        <motion.div
          className={`flex flex-col w-11/12 xs:w-2/3 md:w-3/4 max-w-lg mt-2`}
          animate={{
            opacity: [0, 0, 0, 1],
            top: [2, 2, 2, 0],
          }}
        >
          <button
            disabled={!state.panoReady}
            onClick={() => {
              history.push('/mapa')
            }}
            onMouseOver={(e) =>
              simulateButtonPress(e, true, state.highContrast)
            }
            onMouseLeave={(e) =>
              simulateButtonPress(e, false, state.highContrast)
            }
            className={`flex flex-1 justify-center
          items-center rounded-full text-sm xs:text-base
          relative ${
            state.highContrast
              ? ' btn-thick-contrast text-black bg-yellow-400'
              : 'btn-thick text-white bg-green-600'
          } outline-none`}
          >
            <span className={`${state.fontSize && 'text-lg'}`}>
              {t('Map.Intro').toUpperCase()}
            </span>
          </button>
          <div className="flex flex-row flex-wrap justify-between">
            <Link
              to="/o-projekcie"
              className={`text-center flex flex-grow w-2/5 mr-0 md:mr-2 justify-center
            items-center rounded-full text-sm xs:text-base
            ${
              state.highContrast
                ? 'text-yellow-400 border mt-4 hover:bg-yellow-400 hover:text-black py-3 border-yellow-400'
                : 'text-white border mt-4 hover:bg-white hover:text-gray-700 py-3 border-white'
            } font-bold outline-none`}
            >
              <span className={`${state.fontSize && 'text-lg'}`}>
                {t('About the project.title').toUpperCase()}
              </span>
            </Link>
          </div>
          <div className="flex flex-row justify-center">
            <img
              alt="Interreg - logo"
              src="/logo-interreg-whiteBG.jpg"
              className="max-w-xs h-16 w-auto object-fill mt-5"
            />
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}
export default Intro
